(function () {
  'use strict';

  class ViewResourceCtrl {
    constructor($stateParams, Resources, Utils) {
      let vm = this;
      vm.ctrlName = 'ViewResourceCtrl';
      vm.$stateParams = $stateParams;
      vm.Resources = Resources;
      vm.Utils = Utils;
      vm.init();
    }
    init() {
      let vm = this;
      vm.Resources.resourceView(vm.$stateParams)
        .then((data)=> {
          vm.resource = data.resource;
        })
        .catch((error) => {
          vm.error = error;
        });
    }
  }

  /**
   * @ngdoc object
   * @name viewResource.controller:ViewResourceCtrl
   *
   * @description
   *
   */
  angular
    .module('viewResource')
    .controller('ViewResourceCtrl', ViewResourceCtrl);
}());
